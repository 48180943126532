const config = {
  vic: {
    theme: 'FORMS_LIVE',
    favicon: 'favicon.ico',
    description: 'Forms Live Onboarding',
    ui: 'https://vic.formslive.com.au',
    api: 'https://vic.api.formslive.com.au'
  },
  flsa: {
    theme: "FORMS_LIVE",
    favicon: "favicon.ico",
    description: "Forms Live Onboarding",
    ui: "https://sa.formslive.com.au",
    api: "https://sa.api.formslive.com.au",
  },
  nsw: {
    ui: 'https://nsw.reiformslive.com.au',
    api: 'https://nsw.api.reiformslive.com.au'
  },
  act: {
    ui: 'https://act.reiformslive.com.au/',
    api: 'https://act.api.reiformslive.com.au'
  },
  sa: {
    ui: 'https://sa.reiformslive.com.au',
    api: 'https://sa.api.reiformslive.com.au'
  },
  wa: {
    ui: 'https://wa.reiformslive.com.au',
    api: 'https://wa.api.reiformslive.com.au'
  },
  nt: {
    ui: 'https://nt.reiformslive.com.au',
    api: 'https://nt.api.reiformslive.com.au'
  },
  tas: {
    ui: 'https://tas.reiformslive.com.au',
    api: 'https://tas.api.reiformslive.com.au'
  },
  qld: {
    theme: 'REALWORKS',
    favicon: 'favicon-realworks.ico',
    description: 'Realworks Onboarding',
    ui: 'https://app.realworks.com.au',
    api: 'https://api.realworks.com.au'
  }
};

export default config;