const config = {
  vic: {
    theme: "FORMS_LIVE",
    favicon: "favicon.ico",
    description: "Forms Live Onboarding",
    ui: "https://vic.staging.formslive.com.au",
    api: "https://vic-api.staging.formslive.com.au",
  },
  vicnew: {
    theme: "FORMS_LIVE",
    favicon: "favicon.ico",
    description: "Forms Live Onboarding",
    ui: "https://vic.formslive-staging.com.au",
    api: "https://vic-api.formslive-staging.com.au",
  },
  flsa: {
    theme: "FORMS_LIVE",
    favicon: "favicon.ico",
    description: "Forms Live Onboarding",
    ui: "https://sa.formslive-staging.com.au",
    api: "https://sa-api.formslive-staging.com.au",
  },
  nsw: {
    ui: "https://nsw.staging.reiformslive.com.au",
    api: "https://nsw-api.staging.reiformslive.com.au",
  },
  act: {
    ui: "https://act.staging.reiformslive.com.au",
    api: "https://act-api.staging.reiformslive.com.au",
  },
  sa: {
    ui: "https://sa.staging.reiformslive.com.au",
    api: "https://sa-api.staging.reiformslive.com.au",
  },
  wa: {
    ui: "https://wa.staging.reiformslive.com.au",
    api: "https://wa-api.staging.reiformslive.com.au",
  },
  nt: {
    ui: "https://nt.staging.reiformslive.com.au",
    api: "https://nt-api.staging.reiformslive.com.au",
  },
  tas: {
    ui: "https://tas.staging.reiformslive.com.au",
    api: "https://tas-api.staging.reiformslive.com.au",
  },
  qld: {
    theme: "REALWORKS",
    favicon: "favicon-realworks.ico",
    description: "Realworks Onboarding",
    ui: "https://qld.staging.reiformslive.com.au",
    api: "https://qld-api.staging.reiformslive.com.au",
  },
};

export default config;
