import React, { useState } from 'react';

import Utilities from '../containers/utilities';
import DCLogo from '../images/logo_direct_connect.png';
import useUserDetails from '../hooks/useUserDetails';

const Container = (props) => {
  return (
    <div className="
      flex items-center flex-col w-full px-6
      md:w-3/4 md:mx-auto
      lg:w-2/4 lg:mt-6
      xl:w-2/6
    ">
  
      <div id="header" className="text-left mb-2">
        <p className="mt-4 text-base leading-6 text-blue-600 font-semibold tracking-wide uppercase">Connect Utilities</p>
        <h3 className="my-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          Let's get you moving
        </h3>
        <p className="py-2 text-base leading-6 text-left text-gray-500">
        Direct Connect is a FREE service that can connect you to the following utilities and services in your new home:
        </p>
        <ul className="ml-8 list-disc text-sm leading-6 text-gray-500">
          <li className="">Electricity</li>
          <li className="">Gas</li>
          <li className="">Internet</li>
          <li className="">Phone</li>
          <li className="">Pay TV</li>
          <li className="">Cleaners</li>
          <li className="">Removalist</li>
          <li className="">Storage</li>
          <li className="">Water (VIC only)</li>
        </ul>
        <h4 className="text-md font-semibold text-gray-900 mt-2">I consent to:</h4>        
        <ul className="ml-8 list-disc text-sm leading-6 text-gray-500">
          <li className="my-2">My real estate agent providing my personal information to Direct Connect including name, address, email and phone number.</li>
          <li className="my-2">Direct Connect contacting me by phone, SMS and/or email during my move in relation to electricity, gas and the other services set out above.</li>
          <li className="my-2">Direct Connect obtaining metering information for the premises I am moving to.</li>
        </ul>
      </div>

      {props.children}

      <div className="text-left my-4">
        <hr className="my-4" />
        <h4 className="text-sm text-gray-500 mb-2">Privacy Collection Statement:</h4>
        <p className="text-sm leading-6 text-gray-400">
        Direct Connect Australia Pty Limited (DCA) is collecting your personal information for the purposes of contacting you in relation to your utilities and services connections. DCA will otherwise collect, use and disclose your personal information for purposes set out in its Privacy Policy at <a href="www.directconnect.com.au/privacypolicy/">www.directconnect.com.au/privacypolicy/</a>. This information may be disclosed to third parties that help DCA deliver its services. The Privacy Policy explains how DCA will collect, use, store and disclose your personal information, the consequences for you if DCA does not collect this information, and the way in which you can access and seek correction of your personal information or complain about a breach of the Privacy Act. To obtain further information, you can contact DCA on 1300 664 715.
        </p>
        <hr className="mt-4" />
      </div>

      <div id="provider" className="flex flex-col items-center">
        <img className="px-8 py-4" src={DCLogo} alt="Direct Connect" />
      </div>
    </div>
  );
}

function GetConsent ({ onYesClick, onNoClick }) {
  const [agree, setAgree] = useState(false);
  const [hasClicked, setClicked ] = useState(false);

  function onAgree () {
    setAgree(!agree);
  }

  function onClick () {
    if (agree) {
      onYesClick();
    } else {
      setClicked(true);
    }
  }

  return (
    <>
      <div id="terms-contain" className="pt-2 py-6 sm:col-span-2">
        <div className="flex items-center mx-auto">
          <div className="flex-shrink-0">
            <input id="accept_terms" type="checkbox" className="form-checkbox h-4 w-4 text-red-600 transition duration-150 ease-in-out" onChange={onAgree} checked={agree} />            
          </div>
          <div className="ml-3">
            <p className="text-sm leading-6 text-gray-500">
              I consent and accept the&nbsp;
              <a href="https://www.directconnect.com.au/terms-conditions/" rel="noopener noreferrer" target="_blank" className="font-medium text-gray-700 underline">Terms & Conditions</a> and&nbsp;
              <a href="https://www.directconnect.com.au/privacypolicy/" rel="noopener noreferrer" target="_blank" className="font-medium text-gray-700 underline">Privacy Policy</a>.
            </p>
            { !agree && hasClicked ? (<p className="text-xs text-red-500 font-semibold">You must agree to the privacy policy before proceeding.</p>) : null }
          </div>
        </div>
      </div>
      
      <div id="actions" className="flex items-center justify-center flex flex-col">
        <div className="w-64 inline-flex rounded-md shadow">
          <button onClick={onClick} className="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
            Yes please
          </button>
        </div>
        <div className="w-64 mt-4 inline-flex rounded-md shadow">
          <button onClick={onNoClick} className="w-full inline-flex items-center justify-center px-5 py-3 border border-red-600 text-base leading-6 font-medium rounded-md text-red-600 hover:border-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
            No thanks
          </button>
        </div>
      </div>
    </>
  )
}

function GetUserDetails({ onSubmit, data = {} }) {
  const [ values, validate, renderDetails ] = useUserDetails(data);

  const handleSubmit = () => {
    if (!validate()) return false;
    else onSubmit(values);
  }

  return (
    <div className="w-full">
      {renderDetails()}
      
      <div id="actions" className="flex items-center justify-center flex flex-col mt-6">
        <div className="w-64 inline-flex rounded-md shadow">
          <button onClick={handleSubmit} className="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
            Get Connected!
          </button>
        </div>
      </div>
    </div>
  )
}

const Intro = (props) => <Container><GetConsent {...props} /></Container>
const Details = (props) => <Container><GetUserDetails {...props} /></Container>

function DirectConnect({ api, platform, data, envelopeId, signerId }) {
  return (
    <Utilities 
      data={data}
      Intro={Intro}
      Details={Details}
      providerId={'dc'}
      platform={platform}
      signerId={signerId}
      envelopeId={envelopeId}
    />
  );
}

export default DirectConnect;
