const config = {
  vic: {
    theme: 'FORMS_LIVE',
    favicon: 'favicon.ico',
    description: 'Forms Live Onboarding',
    ui: 'http://localhost:3000',
    api: 'http://localhost:3001'
  },
  flsa: {
    theme: 'FORMS_LIVE',
    favicon: 'favicon.ico',
    description: 'Forms Live Onboarding',
    ui: 'http://localhost:3000',
    api: 'http://localhost:3001'
  },
  nsw: {
    ui: 'http://localhost:3000',
    api: 'http://localhost:3001'
  },
  act: {
    ui: 'http://localhost:3000',
    api: 'http://localhost:3001'
  },
  sa: {
    ui: 'http://localhost:3000',
    api: 'http://localhost:3001'
  },
  wa: {
    ui: 'http://localhost:3000',
    api: 'http://localhost:3001'
  },
  nt: {
    ui: 'http://localhost:3000',
    api: 'http://localhost:3001'
  },
  tas: {
    ui: 'http://localhost:3000',
    api: 'http://localhost:3001'
  },
  qld: {
    theme: 'REALWORKS',
    favicon: 'favicon-realworks.ico',
    description: 'Realworks Onboarding',
    ui: 'http://localhost:3000',
    api: 'http://localhost:3001'
  }
};

export default config;